import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header";
import {Link} from "gatsby";
import {email, facebook, instagram, phone, x} from "../config";
import axios from "axios";
import {Helmet} from "react-helmet";
import WorkArrow from "../components/workArrow";
import {Component} from "react";

class Popup extends Component {
    toggleClass() {
        document.getElementById('popup').classList.remove('show');
    };

    render() {
        return (
            <div id={'popup'} className={'popup'}>
                <div className={'popup-bg'}/>
                <div className={'popup-box'}>
                    <div className={'popup-inner'}>
                        <button type="button" onClick={()=>this.toggleClass()}>
                            <svg  width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g id="Welcome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                                    <g id="MENU" transform="translate(-1354.000000, -72.000000)" stroke="#000000" strokeWidth="5">
                                        <g id="Group" transform="translate(1358.457528, 76.457528)">
                                            <line x1="9.09494702e-13" y1="1.70530257e-13" x2="15.0849447" y2="15.0849447" id="Line"/>
                                            <line x1="15.0849447" y1="5.68434189e-14" x2="0" y2="15.0849447" id="Line"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                        <h3>Thanks for writing, we'll get back to you soon!</h3>
                    </div>
                </div>
            </div>
        )
    }
}


export default class ContactPage extends Component {
    send(e){
        e.preventDefault();

        const formData = new FormData();
        formData.append('email', document.getElementById('email').value);
        formData.append('name', document.getElementById('name').value);
        formData.append('phone', document.getElementById('phone').value);
        formData.append('message', document.getElementById('message').value);

        axios.post('https://getform.io/f/1e01cc05-225f-41de-ba3d-12586af74f09', formData)
            .then(function (response) {
                // handle success
                if(response.status === 200){
                    document.getElementById('popup').classList.add('show');
                    //alert("Thanks for writing, we'll get back to you soon!");
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });

        return false;
    }

    render() {
        const title = 'Get in Touch with Designatives | Creative Agency in Budapest, Hungary';
        const description = 'Have a project in mind? Reach out to Designatives, a creative agency in Budapest, Hungary. Let’s talk about branding, web design, app development, motion design, and more. We’d love to hear from you.';
        return (
            <main>
                <Helmet>
                    <title>{title}</title>

                    <meta name="description" content={description}/>
                    <meta name="DC.description" content={description}/>
                    <meta name="DC.title" content={title}/>

                    <meta property="og:description" content={description}/>
                    <meta property="og:site_name" content={title}/>

                    <meta name="twitter:description" content={description}/>
                </Helmet>
                <Header/>
                <Layout>
                    <div className={'contact-page'}>
                        <Popup/>
                        <section className="contact-section">
                            <div className="container">
                                <div className="row">
                                    <div className={'col-12 col-lg-8'}>
                                        <div className="row">
                                            <div className={'col-12'}>
                                                <h1 className={'level1 mt-3'}>We’d love to hear from you.</h1>
                                            </div>
                                        </div>
                                        <div className={'row mt-2 mt-lg-5'}>
                                            <div className={'col-12 col-md-6 pe-5'}>
                                                <fieldset>
                                                    <legend>Email us</legend>
                                                    <a href={'mailto:'+email}>{email}</a>
                                                </fieldset>
                                            </div>
                                            <div className={'col-12 col-md-6 pe-5'}>
                                                <fieldset>
                                                    <legend>Call us</legend>
                                                    <a href={'tel:'+phone}>{phone}</a>
                                                </fieldset>
                                            </div>
                                            <div className={'col-12 col-md-6 pe-5'}>
                                                <fieldset>
                                                    <legend>Visit us</legend>
                                                    <a href="https://goo.gl/maps/p27cfAJAFvn" target={'_blank'} rel={'noreferrer'}>
                                                        1011 Budapest, Hungary <br/>
                                                        Bem rakpart 25/b
                                                    </a>
                                                </fieldset>
                                            </div>
                                            <div className={'col-12 col-md-6 pe-5'}>
                                                <fieldset>
                                                    <legend>Follow us</legend>
                                                    <a href={instagram} target={'_blank'} rel={'noreferrer'} className={'button mb-3'}>Instagram</a>
                                                    <a href={facebook} target={'_blank'} rel={'noreferrer'} className={'button'}>Facebook</a>
                                                    <a href={x} target={'_blank'} rel={'noreferrer'} className={'button'}>X</a>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col-12 col-lg-4'}>
                                        <form onSubmit={this.send}>
                                            <div className="row">
                                                <div className={'col-12'}>
                                                    <div className={'form-group'}>
                                                        <div className="form-floating">
                                                            <input type="text" className="form-control" id="name" placeholder={'Name'} required/>
                                                            <label htmlFor="name">Name</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'col-12'}>
                                                    <div className={'form-group'}>
                                                        <div className="form-floating">
                                                            <input type="email" className="form-control" id="email" placeholder={'Email address'} required/>
                                                            <label htmlFor="email">Email address</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'col-12'}>
                                                    <div className={'form-group'}>
                                                        <div className="form-floating">
                                                            <input type="tel" className="form-control" id="phone" placeholder={'Phone'} required/>
                                                            <label htmlFor="phone">Phone number</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'col-12'}>
                                                    <div className={'form-group'}>
                                                        <div className="form-floating">
                                                            <textarea name="message" id="message" cols="30" rows="5" placeholder={'Type your text here...'} required/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'col-12'}>
                                                    <button type={'submit'} className={'btn'}>
                                                        <WorkArrow/> Send
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className={'row copyright mt-5'}>
                                    <div className={'col-auto pe-5'}>
                                        <Link to={'/privacy-policy'}>
                                            Privacy Policy
                                        </Link>
                                    </div>
                                    <div className={'col-auto ps-5'}>
                                        2024
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                </Layout>
            </main>
        );
    }
}
